import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  align-items: center;
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ul.skills-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 500px));
    grid-gap: 0 100px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      align-items: center;
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        align-items: center;
        content: "▹";
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const Skills = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const progSkills = [
    'C',
    'C++',
    'Python',
    'Java',
    'JavaScript',
    'Angular',
    'HTML',
    'CSS',
    'SQL',
    'Powershell',
    '.NET',
    'C#',
  ];
  const tech = [
    'Pytorch',
    'TensorFlow',
    'AWS',
    'Flutter',
    'Numpy',
    'Pandas',
    'Mongo',
    'Express',
    'Node',
    'Terraform',
  ];
  const others = [
    'Object Oriented Design and Programming',
    'Databases',
    'Operating Systems',
    'Software Systems Architecture and Design',
    'Human Computer Interaction',
    'Product Management',
    'Neural Networks',
    'Machine Learning',
    'Natural Language Processing',
    'Distributed Systems',
    'Big Data',
    'Internet of Things',
  ];

  return (
    <StyledAboutSection id="skills" ref={revealContainer}>
      <h2 className="numbered-heading">What am I good at?</h2>

      <div className="inner">
        <StyledText>
          <h3>Programming Languages</h3>
          <ul className="skills-list">
            {progSkills &&
              progSkills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
          <br />
          <h3>Technologies and Frameworks</h3>
          <ul className="skills-list">
            {tech && tech.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
          <h3>Concepts</h3>
          <ul className="skills-list">
            {others && others.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default Skills;
